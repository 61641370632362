import React from "react";
import styles from "../styles/AboutPage.module.css";
import { Helmet } from "react-helmet";
import groupImg from "../images/SlideShowImage.png";
import integrity from "../icons/integrity.svg";
import commitment from "../icons/Commitment.svg";
import engagement from "../icons/engagement.svg";
import heroPic from "../images/aboutImg.png";
import Value from "./Value";
import respect from "../icons/Respect.svg";
import AboutHero from "./AboutPageHero";
import Biography from "./Bio";
import Mission from "./Mission";

const aboutPage = {
  hero: {
    Title:
      "Our vision is improving lives by providing quality project management and six sigma quality training to both traditional and non-traditional learners,",
    Bold: " helping them achieve their true potential.",
    Image: heroPic
  },
  bio: {
    Title: "Susan Serradilla-Smarth",
    Content: [
      "Susan Serradilla-Smarth, PMP®, CSSBB, is an expert on project management and six sigma quality. She is the owner and proudly the only instructor for certifyrightaway.com a Smarth Enterprises Inc. company. She personally instructs those wishing to pass the Project Management Professional (PMP)® Exam, Certified Associate in Project Management (CAPM)® Exam, Certified Six Sigma White, Yellow, Green, Black Belt Exams, without wasting time and money. Her approach is focused on understanding the Project Management Processes and their interactions, with limited memorization.",
      "Ms. Serradilla-Smarth lives in the NY-NJ Metropolitan area with her husband, Dennis, and their two rescue German Shepard dogs, Goldie and Freddy.  When not teaching or coaching, Ms. Serradilla-Smarth spends her days training dogs to demonstrate confidence and control in order to certify under Therapy Dogs International."
    ]
  },
  mission: {
    Title: "Our Mission",
    Content:
      "Our mission is to embrace learners, educate and equip them with tools and skills to empower and enable them to realize better future and achieve maximum independence for success in the 21st Century Global Economy.",
    Image: groupImg
  }
};

const values = {
  integrity: {
    Picture: integrity,
    Title: "Integrity",
    Content:
      "We conduct our business in accordance with the highest standards of professional behavior and by honoring our word."
  },
  commitment: {
    Picture: commitment,
    Title: "Commitment",
    Content:
      "We are commied to providing programs and services that ensure excellence in teaching and learning; while acvely engaged every learner to expand their educaonal opportunies. "
  },
  Respect: {
    Picture: respect,
    Title: "Respect",
    Content:
      "We Value each other while encouraging individual contribuon and accountability."
  },
  engagement: {
    Picture: engagement,
    Title: "Engagement",
    Content:
      "We encourage diverse viewpoints and inspire individuals to contribute to transforming lives and to the organizaon."
  }
};

const AboutPage = props => (
  <div>
    <Helmet>
      <title>Susan Serradilla-Smarth | Project Management Expert</title>
      <meta
        name="description"
        content="Smarth Enterprises is dedicated to bringing quality training courses to every type of learner. We help you pass your exams by giving you the right tools and skills."
      />
    </Helmet>
    <AboutHero
      Title={aboutPage.hero.Title}
      Image={aboutPage.hero.Image}
      Bold={aboutPage.hero.Bold}
      styles={styles}
    />
    <div className={styles.GridView}>
      <Biography
        Title={aboutPage.bio.Title}
        Content={aboutPage.bio.Content}
        styles={styles}
      />

      <Mission
        Title={aboutPage.mission.Title}
        Content={aboutPage.mission.Content}
        Image={aboutPage.mission.Image}
        styles={styles}
      />
    </div>

    <div className={styles.valuesContainer}>
      <div className={[styles.secondTitle, styles.white].join(" ")}>
        Our Values
      </div>

      <div className={styles.ValuesGridView}>
        <div className={styles.centerItemsValues}>
          {Object.keys(values).map(key => (
            <Value
              Picture={values[key].Picture}
              Title={values[key].Title}
              Content={values[key].Content}
              key={key}
            />
          ))}
        </div>
      </div>
    </div>
    <div className={styles.Discrimination}>
      <p>Non-Discrimination Policy:</p>
      <div className={styles.DiscriminationContent}>
        Smarthenterpries.com is not affiliated with or restricted to a religious
        group and does not discriminate based on race, creed, color, national
        origin, age, sex, disability or marital status in any of its educational
        programs or activities, employment practices, or admissions policies.
        This policy applies to hiring of all positions and admission of all
        participants into all its programs. Participants with special needs such
        as physical or mental handicaps or learning disabilities are considered
        for admission provided, they meet the entrance requirements. The program
        director is responsible for accepting participants and determining
        whether applicants, including those with special needs, can benefit from
        our offerings.
      </div>
    </div>
  </div>
);

export default AboutPage;
