import React from 'react';
import ThreeShadeCircle from '../icons/ThreeShadeCircle.svg';
import SusanIntro from '../images/SusanIntro.png';
import '../styles/IntroductionSlide.css';

const IntroductionSlide = () => (
	<div className="IntroductionSlide">
		<div className="TextContainer">
			<p className="H3">Welcome! I’M</p>
			<p className="H1">SUSAN SMARTH</p>
			<p className="H2">
				Your one-stop for <span>Six Sigma, PMP, and Agile</span> certification needs!
			</p>
		</div>
		<div className="ImageCircleContainer">
			<img className="Content" src={ThreeShadeCircle} alt="" />
			<img className="Content" src={SusanIntro} alt="" />
		</div>
	</div>
);
export default IntroductionSlide;
