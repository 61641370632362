import React from 'react';
import '../styles/Input.css';

const Input = ({ PlaceHolder, required, name, value, onChange, error }) => {
	return (
		<div className="input">
			<input name={name} placeholder=" " type="text" required={required} value={value} onChange={onChange} />
			<label>
				<span className={error ? 'error' : null}>{PlaceHolder}</span>
			</label>
		</div>
	);
};

export default Input;
