import React from 'react';
import '../styles/Event.css';
import calendarclock from '../icons/CalendarClock.svg';
import LocationIcon from '../icons/MapMarker.svg';
import Course from '../icons/Course.svg';
import TimeIcon from '../icons/Time.svg';
import moment from 'moment';
import Modal from './Modal';
import RegisterForm from './RegisterForm';

//This is the Event Component
export default class Events extends React.Component {
	constructor() {
		super();
		this.state = { isOpen: false, Message: '' };
	}
	componentDidMount() {
		const { StartDate, CourseName, StartTime, EndTime, Location } = this.props.eventInfo
		this.setState({ Message: `Hi Susan,\n\nI would like to register for the '${CourseName}' course on ${moment(StartDate).format('MMMM Do')}, from ${moment(StartTime, 'hh:mm:ss A').format('hh:mm A')} - ${moment(EndTime, 'hh:mm:ss A').format('hh:mm A')} at ${Location}.` })
	}

	handleCloseModal = () => {
		this.setState({ isOpen: !this.state.isOpen });
	};

	render() {
		const { StartDate, Location, CourseName, StartTime, EndTime } = this.props.eventInfo;
		return (
			<div className="Event">
				<div className="Left">
					<div className="TopContainer">
						<p className="DateMonth">{moment(StartDate).format('MMM Do')}</p>
						<p className="DayTime">
							{`${moment(StartDate).format('dddd')}, ${moment(StartTime, 'hh:mm:ss A').format('hh:mm A')}`}
						</p>
					</div>
					<div className="BottomContainer">
						<button className="Register" onClick={() => this.setState({ isOpen: true })}>
							REGISTER
						</button>
						<img className="LeftIcon" src={calendarclock} alt="calendarclock" />
					</div>
				</div>

				<div className="Right">
					<div className="RightContainer">
						<img className="RightIcon" src={Course} alt="CalendarHeart" />
						<span>{CourseName}</span>
					</div>
					<div className="RightContainer">
						<img className="RightIcon" src={LocationIcon} alt="Location" />
						<span>{Location}</span>
					</div>
					<div className="RightContainer">
						<img className="RightIcon" src={TimeIcon} alt="Time" />
						<span>{moment(StartTime, 'hh:mm:ss A').format('hh:mm A') + ' - ' + moment(EndTime, 'hh:mm:ss A').format('hh:mm A')}</span>
					</div>
				</div>
				{this.state.isOpen ? (
					<Modal isOpen={this.state.isOpen}>
						<RegisterForm handleCloseModal={this.handleCloseModal} customMessage={this.state.Message} />
					</Modal>
				) : null}
			</div>
		);
	}
}
