import React from 'react';
import styles from '../styles/GuaranteeVideo.module.css';
import Video from '../Video/smarthvideo.mp4';
import PlayButton from '../icons/Playbutton.svg';

export default class GuaranteeVideo extends React.Component {
	constructor() {
		super();
		this.state = { fullScreen: false };

		// window resize listeners for all browsers
		document.addEventListener('webkitfullscreenchange', this.exitHandler);
		document.addEventListener('mozfullscreenchange', this.exitHandler);
		document.addEventListener('fullscreenchange', this.exitHandler);
		document.addEventListener('MSFullscreenChange', this.exitHandler);
	}

	// on exit full screen
	// pausing the video
	// then setting the state to true
	exitHandler = (e) => {
		if (this.state.fullScreen) {
			e.target.pause();
		}
		this.setState({ fullScreen: true });
	};

	//on play button click
	// making the video full screen
	// than playing the video
	// then setting the state to false
	handleVideoPlaying = (e) => {
		//browser support
		if (this._Video.requestFullscreen) {
			this._Video.requestFullscreen();
		}
		if (this._Video.mozCancelFullScreen) {
			this._Video.mozCancelFullScreen();
		}
		if (this._Video.webkitRequestFullscreen) {
			this._Video.webkitRequestFullscreen();
		}
		this.setState({ fullScreen: false });
		this._Video.play();
	};

	render() {
		return (
			<div className={styles.GuaranteeVideo}>
				<video className={styles.Video} controlsList="nodownload" ref={(el) => (this._Video = el)}>
					<source src={Video} type="video/mp4" />
				</video>

				<div className={styles.GuaranteeVideoInnerShadow} />
				<button className={styles.PlayButton} onClick={this.handleVideoPlaying}>
					<img className={styles.PlayButton} src={PlayButton} alt="" />
				</button>
			</div>
		);
	}
}
