import React from "react";
import ArrowButton from "../icons/ArrowButton.svg";
import style from "../styles/Carousel.module.css";

export default class Carousel extends React.Component {
  constructor() {
    super();
    this.state = {
      disableButton: false,
      numberOfSlides: 0,
      index: 0
    };
  }

  componentDidMount() {
    // setting the number of slides
    this.setState({ numberOfSlides: this.slides.childElementCount }, () => {
      // calling moveSlideAuto functuon
      this.moveSlideAuto();
    });
    this.initialize();
  }

  componentWillUnmount() {
    // than stopping the setTimeOut function
    clearTimeout(this.timeOut);
  }

  //Creating number of buttons for scrolling
  generateDotButtons = () => {
    const numberOfButtons = this.slides.childElementCount;
    let DotButtons = [];
    for (let i = 0; i < numberOfButtons; i++) {
      DotButtons.push(
        <button
          key={i}
          className={`${style.DotButton} ${
            i === 0 ? style.DotButtonActive : ""
            }`}
          onClick={() => this.handleDotButtonClick(i)}
          disabled={this.state.disableButton}
        />
      );
    }
    return DotButtons;
  };

  handleDotButtonClick = i => {
    this.DotButtons.childNodes.forEach(element => {
      element.classList.remove(style.DotButtonActive);
    });
    this.DotButtons.childNodes[i].classList.add(style.DotButtonActive);
    // move slide to the right or left
    this.moveSlide(i);
    // than stopping the setTimeOut function
    clearTimeout(this.timeOut);
    // than calling the moveSlideAuto function again to reset the time
    this.moveSlideAuto();
  };

  // initialize the first slide
  initialize() {
    // showing the first slide
    this.slides.children[this.state.index].style.display = "block";
  }

  //move slide to the right envey 1  minute
  moveSlideAuto = () => {
    // checking if there is only one slide or more than one
    if (this.state.numberOfSlides > 1) {
      this.timeOut = setTimeout(() => {
        // add 1 to the Current slide index to move slide right
        this.moveSlide(this.state.index + 1);

        if (this.props.DotButton) {
          this.DotButtons.childNodes.forEach(element => {
            element.classList.remove(style.DotButtonActive);
          });
          this.DotButtons.childNodes[this.state.index].classList.add(
            style.DotButtonActive
          );
        }

        // Calling it self after 12 second.
        this.moveSlideAuto();
      }, this.props.TimeOut || 12000);
    }
  };

  // move slide to right function
  moveSlideToRight = () => {
    // than adding 1 to the Current slide index to move slide to the right
    this.moveSlide(this.state.index + 1);
    // than stopping the setTimeOut function
    clearTimeout(this.timeOut);
    // than calling the moveSlideAuto function again to reset the time
    this.moveSlideAuto();
  };

  // move slide to left function
  moveSlideToLeft = () => {
    // than subtracting 1 from the Current slide index to move slide to the left
    this.moveSlide(this.state.index - 1);
    // than stopping the setTimeOut function
    clearTimeout(this.timeOut);
    // than calling the moveSlideAuto function again to reset the time
    this.moveSlideAuto();
  };

  stopSlide = state => {
    if (state) {
      // removing the animations classes completely
      this.slides.children[this.state.index].classList.remove(
        "animated",
        "slideInRight",
        "slideInLeft"
      );
      // than stopping the setTimeOut function
      clearTimeout(this.timeOut);
    } else {
      this.moveSlideAuto();
    }
  };

  // move slide to the right or left
  // depending on if the value(n) passed in is greater or less than the current index.
  // n means the next slide to move to, right or left
  moveSlide = n => {
    let currentSlide, nextSlide;

    // first removing all the animation Classes and hiding all the slides
    for (var i = 0; i < this.slides.childElementCount; i++) {
      this.slides.children[i].className = style.SlideWrapper;
      this.slides.children[i].style.display = "none";
    }

    // then Deciding if we need to move the slide to right or left.

    // if n is greater than the current slide then move slide to the left
    if (n > this.state.index) {
      // if n is greater then the number of slides
      // than n is equal to zero, meanning the first slide
      if (n >= this.slides.childElementCount) {
        n = 0;
      }

      // the current slide will move out to the left side
      currentSlide = "slideOutLeft";
      // the next light will move in from the right side
      nextSlide = "slideInRight";

      // if n is less than the current slide then move slide to the right
    } else if (n < this.state.index) {
      // if n is less than 0
      // which means and is -1
      // then we will reset n to be the last slide
      // in this case n will be 2  because we only have to slides
      if (n < 0) {
        n = this.slides.childElementCount - 1;
      }

      // the current slide will out to the right side
      currentSlide = "slideOutRight";
      // the next slide will move in from the left side
      nextSlide = "slideInLeft";
    }

    // finally moving the slide right or left depending on the Decision made above

    // showing the current slide
    this.slides.children[this.state.index].style.display = "block";
    // Adding the animation Classes for the current slide
    this.slides.children[this.state.index].classList.add(
      "animated",
      currentSlide
    );

    // showing the next slide
    this.slides.children[n].style.display = "block";
    // Adding the animation Classes for the next slide
    this.slides.children[n].classList.add("animated", nextSlide);

    // Setting the index equle n
    // to make the next slide to become the current slide
    this.setState({ index: n });
  };

  render() {
    return (
      <div
        className={this.props.className ? this.props.className : style.Carousel}
      >
        <div
          className={style.Slides}
          ref={el => (this.slides = el)}
          //eneble the buttons after the sliding animation stops.
          //so that the user don't inturapet the sliding animation by clilikg the button multipul times.
          onAnimationEnd={() => {
            this.setState({ disableButton: false });
          }}
          //Disable the buttons after the sliding animation start.
          //so that the user don't inturapet the sliding animation by clilikg the button multipul times.
          onAnimationStart={() => {
            this.setState({ disableButton: true });
          }}
        >
          {this.props.render(this.stopSlide)}
        </div>

        {this.props.ArrowButtons ? (
          <div className={style.arrowButtonContainer}>
            <button
              className={`${style.arrowButton} ${style.Left} ${
                this.state.numberOfSlides <= 1 ? style.arrowButtonHide : ""
                }`}
              onClick={this.moveSlideToLeft}
              disabled={this.state.disableButton}
            >
              <div className={style.arrowButtonIconContainer}>
                <img className={style.arrowButtonIcon} src={ArrowButton} alt="" />
              </div>
            </button>
            <button
              className={`${style.arrowButton} ${style.right} ${
                this.state.numberOfSlides <= 1 ? style.arrowButtonHide : ""
                }`}
              onClick={this.moveSlideToRight}
              disabled={this.state.disableButton}
            >
              <div className={style.arrowButtonIconContainer}>
                <img className={style.arrowButtonIcon} src={ArrowButton} alt="" />
              </div>
            </button>
          </div>
        ) : (
            ""
          )}

        {this.props.EEER ? (
          <div className={style.EEER}>
            <p>Embrace x Educate x Empower = Results!</p>
          </div>
        ) : (
            ""
          )}

        {this.props.DotButton ? (
          <div
            className={style.DotButtonContainer}
            ref={el => (this.DotButtons = el)}
          >
            {this.state.numberOfSlides ? this.generateDotButtons() : ""}
          </div>
        ) : (
            ""
          )}
      </div>
    );
  }
}

// wraps around slide
const SlideWrapper = props => {
  return <div className={style.SlideWrapper}>{props.children}</div>;
};

export { Carousel, SlideWrapper };
