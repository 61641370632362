import React from "react";
import styles from "../styles/Accordion.module.css";
import Icon from "../icons/Triangle.svg";

export default class Accordion extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false
    };
  }

  toggleAccordion = () => {
    this.setState(state => ({
      open: !state.open
    }));
  };

  render() {
    // Setting up styles before rendering
    let questionClassNames = [styles.question];
    let answerClassNames = [
      this.state.open ? styles.answer : styles.answerHide
    ];
    let iconClassNames = [
      this.state.open
        ? this.props.openIconStyles || styles.openIcon
        : this.props.closedIconStyles || styles.closedIcon
    ];
    return (
      <div>
        <button
          id="question"
          className={questionClassNames.join(" ")}
          style={this.props.question.textStyles}
          onClick={this.toggleAccordion}
        >
          {this.props.question.text}
          <img
            id="icon"
            src={this.props.icon || Icon}
            className={iconClassNames.join(" ")}
            alt="FAQ icon"
          />
        </button>
        <div
          id="answerContainer"
          className={answerClassNames.join(" ")}
          style={this.props.answer.containerStyles}
        >
          <p
            id="answerText"
            className={styles.answerText}
            style={this.props.answer.TextStyles}
          >
            {this.props.answer.text}
          </p>
        </div>
      </div>
    );
  }
}
