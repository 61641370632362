import React from 'react';

export default class InViewport extends React.Component {
	componentDidMount() {
		// Listening to window resize
		window.addEventListener('resize', this.onScroll);

		// Listening for window scroll
		document.querySelector('.simplebar-content-wrapper').addEventListener('scroll', this.onScroll);

		this.onScroll();
	}

	componentWillUnmount() {
		// removing Event Listener to window resize
		window.removeEventListener('resize', this.onScroll);

		// removing Event Listener for window scroll
		document.querySelector('.simplebar-content-wrapper').removeEventListener('scroll', this.onScroll);
	}

	// return true if element is in viewport
	handleInViewPort = function(elem) {
		// getting element's positions
		const bounding = elem.getBoundingClientRect();
		return (
			// if element is in viewport return true
			bounding.top >= 0 &&
			bounding.left >= 0 &&
			bounding.bottom <= (window.innerHeight || document.documentElement.clientHeight) &&
			bounding.right <= (window.innerWidth || document.documentElement.clientWidth)
		);
	};

	onScroll = () => {
		// on scroll check if element is in viewport
		if (this.handleInViewPort(this._el)) {
			// if true than do something
			this.props.onViewPort(this._el);
		}
	};

	render() {
		const { className, children } = this.props;
		return (
			<div className={className} ref={(el) => (this._el = el)}>
				{children}
			</div>
		);
	}
}
