import React from "react";
import Modal from "./Modal";
import ContactForm from "./ContactForm";
import Phone from "../icons/PhoneClassic.svg";
import Location from "../icons/MapMarkerWhite.svg";
import Email from "../icons/Email.svg";
import YoutubeIcon from "../icons/Youtube.svg";
import LinkedinIcon from "../icons/LinkedinBox.svg";
import styles from "../styles/Footer.module.css";
import Logo from "../icons/Whitecharacter-stacked-white.svg";

export default class Footer extends React.Component {
  //initiating state
  constructor() {
    super();
    this.state = { isOpen: false };
  }

  //Contact alert
  handleOpenModal = () => {
    this.setState({ isOpen: true });
  };
  handleCloseModal = () => {
    this.setState({ isOpen: false });
  };

  //subimit the tip of the day
  handleFormSubmit = e => {
    e.preventDefault();
    if (e.target.elements.phoneNumber.value) {
      alert("Feature Under Progress!");
    }
  };

  render() {
    const { About, Courses, OnLocation, FAQ } = this.props;

    return (
      <div className={styles.Footer}>
        <div className={styles.Grid}>
          <div className={styles.TipOfDayContent}>
            <img className={styles.Logo} src={Logo} />
            <p className={styles.title}>Tip Of The Day</p>
            <p className={styles.title2}>
              Embrace x Educate x Empower = Results!
            </p>
            <form className={styles.inputForm} onSubmit={this.handleFormSubmit}>
              <input
                className={styles.inputf}
                type={styles.text}
                placeholder="     Enter Your Phone Number"
                name="phoneNumber"
                maxLength="17"
              />
              <button className={styles.inputButton} type="submit">
                SIGN UP
              </button>
            </form>
          </div>
          <div className={styles.moreInformation}>
            <div className={styles.supportContainer}>
              <p className={styles.moreInfoTitles}>SUPPORT</p>
              <a href={FAQ}>FAQ</a>
              <p className={styles.contact} onClick={this.handleOpenModal}>
                Contact
              </p>
            </div>
            <div className={styles.exploreContainer}>
              <p className={styles.moreInfoTitles}>EXPLORE</p>
              <a href={About}>About</a>
              <a href={Courses}>Courses</a>
              <a href={OnLocation}>On Location</a>
            </div>
            <div className={styles.connectContainer}>
              <p className={styles.moreInfoTitles}>CONNECT</p>
              <a href="https://www.linkedin.com/in/serradillasmarth/">
                <img className={styles.footerImg} src={LinkedinIcon} alt="" />
                Linkedin
              </a>
              <a href="https://www.youtube.com/">
                <img className={styles.footerImg} src={YoutubeIcon} alt="" />
                Youtube
              </a>
            </div>
            <div className={styles.contactContainer}>
              <p className={styles.moreInfoTitles}>CONTACT</p>
              <p>
                <img className={styles.footerImg} src={Location} alt="" />
                PO Box 143 Whippany, NJ 07981-0143
              </p>
              <p>
                <img className={styles.footerImg} src={Email} alt="" />
                se_associates@yahoo.com
              </p>
              <p>
                <img className={styles.footerImg} src={Phone} alt="" />
                +1(973)-615-2820
              </p>
            </div>
          </div>
        </div>
        {this.state.isOpen ? (
          <Modal
            isOpen={this.state.isOpen}
            handleCloseModal={this.handleCloseModal}
          >
            <ContactForm handleCloseModal={this.handleCloseModal} />
          </Modal>
        ) : null}
      </div>
    );
  }
}
