import React from "react";
import "../styles/Modal.css";

export default class Modal extends React.Component {
  constructor(props) {
    super(props);
  }
  componentDidMount() {
    document.body.style.overflow = "hidden";
  }
  componentWillUnmount() {
    document.body.style.overflow = "auto";
  }
  render() {
    const { children, isOpen, handleCloseModal } = this.props;
    return (
      <div
        onClick={handleCloseModal}
        className={isOpen ? "Modal Show" : "Modal Hide"}
      >
        {children}
      </div>
    );
  }
}
