import React from "react";
import Styles from "../styles/PromotionalSlide.module.css";
import DoubleCheck from "../icons/DoubleCheck.svg";
import Modal from "./Modal";
import RegisterForm from "./RegisterForm";
import m from 'moment'
import { withRouter } from "react-router-dom";
import moment from "moment";

class PromotionalSlide extends React.Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      Message: "",
      promotions: {
        StartDate: '',
        EndDate: '',
        StartTime: '',
        EndTime: '',
        PromotionalMessage: 'WE SAVE EXAM PREPARATION TIME DOWN ON AVERAGE FROM NINE TO TWELVE MONTHS TO TWO AND HALF WEEKS',
        SubMessage: 'Find a PMP, CAPM, Six Sigma Training Course Near YOU!',
        Location: '',
      }
    };
  }

  componentDidMount() {
    fetch('https://api.smarthenterprises.com/promotions?active=true')
      .then((response) => response.json())
      .then((data) => {
        this.setState({ promotions: { ...this.state.promotions, ...Object.values(data)[0] } }, () => {
          const { StartDate, EndDate, StartTime, EndTime, PromotionalMessage, Location } = this.state.promotions
          this.setState({ Message: `Hi Susan,\n\nI would like to register for the "${PromotionalMessage}" course from ${moment(StartDate).format('ddd MMM DD')}, ${moment(StartTime, 'hh:mm:ss A').format('hh:mm A')} - ${moment(EndDate).format('ddd MMM DD')}, ${moment(EndTime, 'hh:mm:ss A').format('hh:mm A')} at ${Location}.` })
        })
      })
  }

  handleRegister = () => {
    this.props.stopSlide(true);
    this.setState({ isOpen: true });
  }

  handleCloseModal = () => {
    this.props.stopSlide(false);
    this.setState({ isOpen: !this.state.isOpen });
  };

  render() {
    const { StartDate, EndDate, StartTime, EndTime, PromotionalMessage, SubMessage, Location } = this.state.promotions
    return (
      <React.Fragment>
        <div className={Styles.PromotionalSlide}>
          <p className={Styles.Message}>{PromotionalMessage}</p>
          {StartDate ? <React.Fragment>
            <div className={Styles.DateTime} >
              <p>DATE & TIME:</p>
              <p>{`FORM: ${moment(StartDate).format('ddd MMM DD')}, ${moment(StartTime, 'hh:mm:ss A').format('hh:mm A')}`}</p>
              <p>{`TO: ${moment(EndDate).format('ddd MMM DD')}, ${moment(EndTime, 'hh:mm:ss A').format('hh:mm A')}`}</p>
            </div>
            <div className={Styles.Location}>
              <p>LOCATION:</p>
              <p>{Location}</p>
            </div>
          </React.Fragment>
            : <p className={Styles.SubMessage}>{SubMessage}</p>}

          {StartDate ? <button className={Styles.Register} onClick={this.handleRegister}>
            <img className={Styles.DoubleCheck} src={DoubleCheck} alt="" />REGISTER NOW
              </button>
            : <button className={Styles.Register} onClick={() => this.props.history.push("/course")}>
              <img className={Styles.DoubleCheck} src={DoubleCheck} alt="" />COURSE OFFERINGS
                </button>}
        </div>
        {this.state.isOpen ? (
          <Modal isOpen={this.state.isOpen}>
            <RegisterForm
              handleCloseModal={this.handleCloseModal}
              customMessage={this.state.Message}
            />
          </Modal>
        ) : null}
      </React.Fragment>
    );
  }
}

export default withRouter(PromotionalSlide);
