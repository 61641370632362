import React, { useState, useRef, useEffect } from 'react'
import style from '../styles/Video.module.css';
import PlayButton from '../icons/CirclePlayButton.svg';
import { Power3, TimelineMax } from 'gsap'

const video = (props) => {
    const { src } = props
    let video = useRef()
    let container = useRef()
    const [modalOpen, setModalOpen] = useState(false)
    const [paused, setPaused] = useState(true)
    const [bounds, setBounds] = useState(null)
    var tl = new TimelineMax()

    useEffect(() => {
        video.disablePictureInPicture = true
        video.allowfullscreen = false
        video.addEventListener('fullscreenchange', exitHandler, false);
        video.addEventListener('mozfullscreenchange', exitHandler, false);
        video.addEventListener('MSFullscreenChange', exitHandler, false);
        video.addEventListener('webkitfullscreenchange', exitHandler, false);
        video.addEventListener('webkitendfullscreen', exitHandler, false);
    }, [])

    const exitHandler = (e) => {
        let fullScreen = document.fullScreen || document.mozFullScreen || document.webkitIsFullScreen;
        fullScreen = fullScreen ? true : false
        if (!fullScreen) {
            e.target.controls = false
            e.target.pause()
            setPaused(true)
        }

    }

    const openModal = (e) => {
        if (!modalOpen) {
            const { top, left, width } = container.getBoundingClientRect()
            container.style.top = top + 'px'
            container.style.left = left + 'px'
            container.style.width = width + 'px'
            container.style.position = 'fixed';
            setBounds({ top: top + 'px', left: left + 'px', width: width + 'px' })

            tl.to(container, 0.5, {
                top: '50%',
                left: '50%',
                xPercent: -50,
                yPercent: -50,
                width: '50%',
                zIndex: 100,
                ease: Power3.easeIn,
                onComplete: () => setModalOpen(true)
            })
            video.play()
        }
    }

    const closeModal = (e) => {
        if (modalOpen) {
            const { top, left, width } = bounds
            tl.to(container, 0.5, {
                onStart: () => setModalOpen(false),
                top: top,
                left: left,
                xPercent: 0,
                yPercent: 0,
                width: width,
                zIndex: 0,
                ease: Power3.easeIn,
                onComplete: function () {
                    this.target.style.position = 'static'
                    this.target.style.width = '100%'
                }
            })

            pausedVideo()
        }
    }

    const playVideo = (e) => {
        if (window.innerWidth >= 897) {
            openModal()
        } else if (video.requestFullscreen) {
            video.requestFullscreen();
        } else if (video.mozRequestFullScreen) { /* Firefox */
            video.mozRequestFullScreen();
        } else if (video.webkitRequestFullscreen) { /* Chrome, Safari and Opera */
            video.webkitRequestFullscreen();
        } else if (video.msRequestFullscreen) { /* IE/Edge */
            video.msRequestFullscreen();
        }
        video.controls = true
        video.play()
        setPaused(false)
    }

    const pausedVideo = (e) => {
        video.controls = false
        video.pause()
        setPaused(true)
    }

    return (
        <div className={style.container} ref={(el) => container = el}>

            <div className={`${modalOpen ? style.modalOpen : style.modalClose}`} onClick={closeModal} />
            <video className={`${style.video} ${paused ? '' : style.video_grayscale} ${modalOpen && window.innerWidth >= 897 ? style.video_radius : ''}`}
                controlsList="nodownload nofullscreen"
                ref={(el) => video = el}
            >
                <source src={`${src}#t=0.5`} />
            </video>
            {
                paused ? <button className={style.playButton} onClick={playVideo}>
                    <img src={PlayButton} alt="" />
                </button> : ''
            }
        </div >
    )
}

export default video