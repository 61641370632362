import React from 'react';

const Bio = (props) => (
	<div className={props.styles.contentHolder}>
		<div>
			<h1 className={props.styles.title}>{props.Title}</h1>
		</div>

		<div className={props.styles.centerItems}>
			{props.Content.map((content, i) => (
				<p className={props.styles.content} key={i}>
					{content}
				</p>
			))}
		</div>
	</div>
);

export default Bio;
