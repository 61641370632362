import React from "react";
import Accordion from "./Accordion";
import icon from "../icons/Triangle.svg";
import help from "../icons/Help.svg";
import styles from "../styles/FAQPage.module.css";

const questions = [
  {
    question: {
      text: "Lorem ipsum dolor sit amet?"
    },
    answer: {
      text:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo"
    }
  },
  {
    question: {
      text: "Lorem ipsum dolor sit amet?"
    },
    answer: {
      text:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo"
    }
  },
  {
    question: {
      text: "Lorem ipsum dolor sit amet?"
    },
    answer: {
      text:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo"
    }
  },
  {
    question: {
      text: "Lorem ipsum dolor sit amet?"
    },
    answer: {
      text:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo"
    }
  },
  {
    question: {
      text: "Lorem ipsum dolor sit amet?"
    },
    answer: {
      text:
        "Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam, eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae vitae dicta sunt explicabo"
    }
  }
];

const FAQPage = () => (
  <div>
    <div className={styles.container}>
      <div id="Heading" className={styles.headingContainer}>
        <div className={styles.heading}>Frequently Asked Questions</div>
        <img className={styles.helpIcon} src={help} alt="Help Icon" />
      </div>

      {questions.map((data, index) => (
        <Accordion
          key={index}
          question={data.question}
          icon={icon}
          answer={data.answer}
        />
      ))}
    </div>
  </div>
);

export default FAQPage;
