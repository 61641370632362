import React from 'react';
import HandsHelping from '../icons/HandsHelping.svg';
import styles from '../styles/BodyIcon.module.css';

const BodyIcon = ({ icon, text }) => {
	return (
		<div className={styles.Container}>
			<img className={styles.Icon} src={icon ? icon : HandsHelping} alt="" />
			<p className={styles.Text}>
				{text ? text : 'This is better for security but its not free we can buy one similar to this one'}
			</p>
		</div>
	);
};
export default BodyIcon;
