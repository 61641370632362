import React from 'react';

import style from '../styles/Values.module.css';
const Values = (props) => (
	<div  className={style.Values}>
		<div>
			<img src={props.Picture} alt="integrity" className={style.ValueImg} />
		</div>
		<div className={style.white}>{props.Title}</div>
		<div className={style.LineDivider}> </div>
		<div className={style.valueDescription}>{props.Content}</div>
	</div>
);

export default Values;
