import React, { useState } from "react";
import { Helmet } from "react-helmet";
import style from "../styles/CoursePage.module.css";
import chairPic from "../images/chair.png";
import Course from "../components/Course";
import CourseList from "./CourseList";
import PMPCAMP from "../CourseFiles/SE-PMP6thED-Bootcamp-1-0-SYL.pdf";
import IntroToLEANThinking from "../CourseFiles/IntroToLEANThinking.pdf";
import PracticalApplications from "../CourseFiles/PracticalApplications.pdf";
import IntroToSixSigma from "../CourseFiles/IntroToSixSigma.pdf";
import GreenBeltTeamProject from "../CourseFiles/GreenBeltTeamProject.pdf";
import SIXSIGMAGreenBeltCourse from "../CourseFiles/SIXSIGMAGreenBeltCourse.pdf";
import BlackBeltCourse from "../CourseFiles/BlackBeltCourse.pdf";

const courses = [
  {
    id: 1,
    CourseName: "PMP/CAPM",
    CourseType: "Boot Camp",
    EachDay: [
      "This course is taught by a Certified Project Management Professional using the latest PMBok®, Crosswind Primer, PDF, Solutions Manual, and Electronic Exam. Examples of Six Sigma projects, case studies and success stories are given.",
      "The course also uses PowerPoint, Word, Excel and Visio, as appropriate. There are selected handouts. Quizzes and practice tests are also included.",
      "Successful graduates may be eligible to sit for either the PMP® or CAPM® exam from the Project Management Institute (i.e. www.PMI.org)."
    ],
    Learn:
      "To understand what Project Management is in general. It’s importance and the three basic goals on which it stands: Project scope, Project Cost, Project Duration. To see the business framework in which it operates and explore the “life cycle” of a project. To learn to pass the PMP® Exam, a project manager must practice with hundreds and hundreds of sample questions. Smarth Enterprises Inc. provides hundreds of sample questions with detailed solutions explaining why the best answer is best and the wrong answers are wrong.",
    Syllabus: PMPCAMP
  },
  {
    id: 2,
    CourseName: "Introduction to LEAN Thinking",
    CourseType: "Boot Camp",
    EachDay: [
      "Reference is made to a wide variety of Lean resources.",
      "Examples of Lean Six Sigma projects, case studies, and success stories are given. The relationship between Lean Thinking and Six Sigma is also explored.",
      "This mini-course is taught by an ASQ Certified Six Sigma Green Belt using PowerPoint slides and handouts."
    ],
    Learn:
      "This 8-hour mini-course introduces students to Lean Thinking. It explores the history of Lean Thinking Lean terminology, core concepts of Lean, and Lean calculations. It covers the DMALC Define-Measure-Analyze-Lean-Control methodology, the House of Lean, Value Stream Mapping, 5S Housekeeping for Workplace Organization, Visual Management, the 7 wastes, Kanban, Standard Work, Quick Changeover, Total and Productive Maintenance. We learn about Takt Time, Theory of Constraints, Failure Mode and Effects Analysis, Root Cause Analysis, Poka- Yoke, and Kaizen. We look at Lean project teams, the Lean toolbox, and the benefits of Lean thinking.",
    Syllabus: IntroToLEANThinking
  },
  {
    id: 3,
    CourseName: "Practical Applications of Process Improvement using Minitab",
    CourseType: "Boot Camp",
    EachDay: [
      "This course is taught by a Certified Six Sigma Black Belt using Minitab Software. Examples of process improvement projects, case studies and success stories are given.",
      "The emphasis is on the practical application of process and data analysis and is not intended to be a thoroughly inclusive introduction to Six Sigma or all of Minitab’s features.",
      "The course also uses Notepad, Excel and PowerPoint as appropriate. There are selected handouts and homework as appropriate."
    ],
    Learn:
      "This 25-hour course is based in the practical application of process improvement analysis. Appropriate techniques for meaningful project results that enable sound business decisions. The course is intended for those professionals who are engaged in Lean Manufacturing, Process Improvement and Six Sigma, but are not familiar with the ease, usefulness and application of Minitab for project management.",
    Syllabus: PracticalApplications
  },
  {
    id: 4,
    CourseName: "Introduction to Six Sigma",
    CourseType: "Boot Camp",
    EachDay: [
      "Reference is made to a wide variety of Six Sigma resources.",
      "Examples of Six Sigma projects, case studies and success stories are given. The relationship between Six Sigma and Lean Thinking is also explored.",
      "This mini-course is taught by an ASQ Certified Six Sigma Green Belt using PowerPoint slides and handouts."
    ],
    Learn:
      "This 8-hour mini-course introduces students to Six Sigma Quality. It explores the history of Six Sigma, Six Sigma terminology, core concepts of Six Sigma, and the Statistical Basis of Six Sigma. It covers project selection, problem-solving categories, the DMAIC (Define-Measure- Analysis-Improve-Control) methodology and the methodologies for DFSS (Design for Six Sigma). Students learn about belt colors, project teams, the Six Sigma toolbox, and the benefits of Six Sigma. The ASQ (American Society for Quality) Six Sigma BOK (Body of Knowledge) is reviewed as well as the requirements for taking the ASQ Six Sigma Green Belt and Black Belt Examinations.",
    Syllabus: IntroToSixSigma
  },
  {
    id: 5,
    CourseName: "SIX SIGMA Green Belt TP",
    CourseType: "Team Project",
    EachDay: [
      "The course uses PowerPoint, Word, Excel and Visio, as appropriate. There are selected handouts.",
      "Emphasize is placed upon student participation in the Six Sigma Green Belt Project. (Lean Six Sigma Project if students took Intro to Lean course.)",
      "Students are expected to complete individual and group assignments between class sessions."
    ],
    Learn:
      "This 20-hour course uses the American Society for Quality Six Sigma Green Belt Body of Knowledge (Overview: Six Sigma & the Organization, Define, Measure, Analyze, Improve & Control). It applies the DMAIC Methodology at the Green Belt level to an actual project at the company. This course is taught by an ASQ Certified Six Sigma Black Belt with extensive experience facilitating projects.",
    Syllabus: GreenBeltTeamProject
  },
  {
    id: 6,
    CourseName: "SIX SIGMA Green Belt BC",
    CourseType: "Boot Camp",
    EachDay: [
      "This course is taught by an ASQ Certified Six Sigma Green Belt using the Quality Council of Indiana CSSGB Primer, PDF, Solutions Manual, and Electronic Exam. Examples of Six Sigma projects, case studies and success stories are given.",
      "The emphasis is on the information in the Green Belt Body of Knowledge.",
      "The course also uses PowerPoint, Word, Excel and Visio, as appropriate. There are selected handouts. Quizzes and practice tests are also included.",
      "Successful graduates may be eligible to sit for the ASQ CSSGB Exam"
    ],
    Learn:
      "This 40-hour course is based upon the American Society for Quality Six Sigma Green Belt Body of Knowledge (Overview: Six Sigma and the Organization, Define, Measure, Analyze, Improve and Control). It teaches the DMAIC Methodology at the Green Belt level.",
    Syllabus: SIXSIGMAGreenBeltCourse
  },
  {
    id: 7,
    CourseName: "SIX SIGMA Black Belt Bridge",
    CourseType: "Boot Camp",
    EachDay: [
      "This course is taught by an ASQ Certified Six Sigma Black Belt using the Quality Council of Indiana CSSBB Primer, PDF, Solutions Manual, and Electronic Exam. Examples of Six Sigma projects, case studies and success stories are given.",
      "The emphasis is on the information in the Black Belt Body of Knowledge that is beyond the information in the Green Belt Body of Knowledge.",
      "The course also uses PowerPoint, Word, Excel and Visio, as appropriate. There are selected handouts. Quizzes and practice tests are also included."
    ],
    Learn:
      "This 40-hour course is based upon the American Society for Quality Six Sigma Black Belt Body of Knowledge (Enterprise-wide Deployment, Organizational Process Management and Measures, Team Management, Define, Measure, Analyze, Improve, Control, Design for Six Sigma Frameworks and Methodologies). It teaches the DMAIC Methodology at the Black Belt level by building on the student’s Green Belt Knowledge. Successful graduates may be eligible to sit for the ASQ CSSBB Exam.",
    Syllabus: BlackBeltCourse
  }
];

const CoursePage = () => {
  const [course, setcourse] = useState(courses[0]);

  return (
    <div className={style.coursePage}>
      <Helmet>
        <title>Live and virtual classrooms with two full practice exams.</title>
        <meta
          name="description"
          content="Six Sigma green and black belt, as well as PMP courses. 100% Money-Back Pass Guarantee | Get a refund when you do not pass after 3 attempts."
        />
      </Helmet>
      <img className={style.chairImg} src={chairPic} alt="" />
      <Course course={course} />
      <CourseList
        courseList={courses}
        position={course.id}
        handleCurrentClass={id => {
          setcourse(courses.find(course => course.id === id));
        }}
      />
    </div>
  );
};
export default CoursePage;
