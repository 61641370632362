import React from "react";
import Input from "./Input";
import Textarea from "./Textarea";
import Confirmation from "./Confirmation";
import Close from "../icons/Close.svg";
import "../styles/RegisterForm.css";

export default class RegisterForm extends React.Component {
  constructor() {
    super();
    this.state = {
      Name: "",
      Email: "",
      Subject: "",
      Phone: "",
      Message: "",
      Success: false,
      InvalidEmail: null,
      errorEmail: false
    };
  }

  componentDidMount() {
    this.setState({ Message: this.props.customMessage });
  }

  handleCloseModal = () => {
    this.props.handleCloseModal();
  };

  handleFormSubmit = e => {
    e.preventDefault();
    fetch(
      "https://script.google.com/macros/s/AKfycbxVRLW_apiLWaFdgeB00LOmb0Ia0Ysk_7gLOvkm9A/exec",
      {
        method: "POST",
        body: JSON.stringify(this.state),
        "Content-Type": "application/json"
      }
    )
      .then(res => res.json())
      .then(response => {
        if (response.Success) {
          this.setState({ Success: true });
        } else if (!response.Success) {
          this.setState({ InvalidEmail: "Email is invalid", errorEmail: true });
        }
      });
  };

  handleOnChange = e => {
    this.setState({ [e.target.name]: e.target.value });
  };

  render() {
    return (
      <div
        onClick={e => {
          e.stopPropagation();
        }}
        className="RegisterForm animated slideInDown faster"
      >
        <button className="CloseButton" onClick={this.handleCloseModal}>
          <img src={Close} alt="" />
        </button>
        {!this.state.Success ? (
          <React.Fragment>
            <p className="SubTitle">
              Please provide your contact information below, and we will get
              back to you shortly with more information regarding this course
            </p>
            <form onSubmit={this.handleFormSubmit} ref={e => (this._form = e)}>
              <div className="Container">
                <div className="Input">
                  <Input
                    PlaceHolder="Name*"
                    name="Name"
                    value={this.state.Name}
                    onChange={this.handleOnChange}
                    required
                  />
                </div>
                <div className="Input">
                  <Input
                    PlaceHolder={this.state.InvalidEmail || "Email Address*"}
                    name="Email"
                    value={this.state.Email}
                    onChange={this.handleOnChange}
                    error={this.state.errorEmail}
                    required
                  />
                </div>
              </div>
              <div className="Container">
                <div className="Input">
                  <Input
                    PlaceHolder="Phone*"
                    name="Phone"
                    value={this.state.Phone}
                    onChange={this.handleOnChange}
                    required
                  />
                </div>
              </div>
              <div className="Container">
                <div className="Textarea">
                  <Textarea
                    PlaceHolder="Tell us how we can help...."
                    name="Message"
                    value={this.state.Message}
                    onChange={this.handleOnChange}
                    required
                  />
                </div>
              </div>
              <button className="FromSubmit">Send Message</button>
            </form>
            <div className="MessageContainer">
              <p className="message">
                *We do not share this data with any third-parties.
              </p>
              <p className="message">Expect a response within 48-hours</p>
            </div>
          </React.Fragment>
        ) : (
          <Confirmation title="Thank You For Showing Your Interest In This Course!" />
        )}
      </div>
    );
  }
}
