import React from 'react';
import styles from '../styles/Guarantee.module.css';
import InViewport from './InViewport';
import BodyIcon from './BodyIcon';
import BodyWrapper from './BodyWrapper';
import ShakingHand from '../icons/ShakingHand.svg';
import Wand from '../icons/Wand.svg';
import Search from '../icons/Search.svg';
import Recycle from '../icons/Recycle.svg';
import Earnings from '../icons/Earnings.svg';
import Shield from '../icons/Shield.svg';

export default class Guarantee extends React.Component {
	constructor() {
		super();
		this.state = {
			bodySecondAttempt: false,
			bodyThirdAttempt: false,
			data: {
				SubTitle:
					"We reimburse the PMI member cost of the exam two additional times because we’re confident\
					 that our exam prep for the PMP® and CAPM® course thoroughly prepares you to successfully \
					 sit for the PMI certification exams. It's our sincere goal to help people become PMP \
					 certified, and we stand by our students in their PMP certification pursuit.",
				Body: [
					{
						BodyText: [
							'If you do not pass the PMP exam, within 7 days',
							'after course completion, your instructor will create',
							'a personalized momentum program to help you',
							'shore up your weak areas that will include guiding',
							'you through the process of rescheduling your',
							'exam.'
						],
						BodyIcons: [
							{
								Icon: Search,
								Text: 'Identify Weak Areas'
							},
							{
								Icon: ShakingHand,
								Text: 'Instructor to student support'
							},
							{
								Icon: Wand,
								Text: 'Enhance Weak Areas'
							}
						]
					},
					{
						BodyText: [
							'In the event you do not pass the PMP exam after',
							'the third attempt (all three attempts must be after',
							'attending the exam prep for the PMP Classroom',
							'course), and you have abided by the refund',
							'guarantee guidelines, we will fully refund the fee **',
							'balance less cost ** you paid us to take the course',
							'upon request.'
						],
						BodyIcons: [
							{
								Icon: Earnings,
								Text: 'Refund Elegibility'
							},
							{
								Icon: Shield,
								Text: 'Money Back Gurantee'
							},
							{
								Icon: Recycle,
								Text: 'Multiple attempts...'
							}
						]
					}
				]
			}
		};
	}

	render() {
		return (
			<div className={styles.Guarantee}>
				<div className={styles.Title}>MY GUARANTEE</div>
				<div className={styles.SubTitle}>{this.state.data.SubTitle}</div>
				<InViewport
					className={styles.Body}
					onViewPort={(e) => {
						e.style.opacity = 1;
						this.setState({ bodySecondAttempt: true });
					}}
				>
					<BodyWrapper
						className={styles.BodyText}
						animated="fadeInUp"
						inViewPort={this.state.bodySecondAttempt}
					>
						{// mapping body text
						this.state.data.Body[0].BodyText.map((text, i) => <p key={i}>{text}</p>)}
					</BodyWrapper>
					<BodyWrapper
						className={styles.BodyIcon}
						animated="slideInRight"
						inViewPort={this.state.bodySecondAttempt}
					>
						{// mapping body icons and text
						this.state.data.Body[0].BodyIcons.map((value, i) => (
							<BodyIcon key={i} icon={value.Icon} text={value.Text} />
						))}
					</BodyWrapper>
				</InViewport>

				<InViewport
					className={styles.Body}
					onViewPort={(e) => {
						e.style.opacity = 1;
						this.setState({ bodyThirdAttempt: true });
					}}
				>
					<BodyWrapper
						className={styles.BodyText}
						animated="fadeInUp"
						inViewPort={this.state.bodyThirdAttempt}
					>
						{// mapping body text
						this.state.data.Body[1].BodyText.map((text, i) => <p key={i}>{text}</p>)}
					</BodyWrapper>
					<BodyWrapper
						className={styles.BodyIcon}
						animated="slideInLeft"
						inViewPort={this.state.bodyThirdAttempt}
					>
						{// mapping body icons and text
						this.state.data.Body[1].BodyIcons.map((value, i) => (
							<BodyIcon key={i} icon={value.Icon} text={value.Text} />
						))}
					</BodyWrapper>
				</InViewport>
			</div>
		);
	}
}
