import React from 'react';


const Hero=(props)=>(
    <div className={props.style.mainContainer}>
        <div className={props.style.GridView}>
                <div className={props.style.textContainer}>
                    <div className={[props.style.headerTitle, props.style.headerCont].join(" ")}>{props.Title}</div>
                    <div className={[props.style.headerCont,props.style.headerText].join(" ")}>{props.Content}</div>
                </div> 
                <div className={props.style.headerImgCont}>  </div>
        </div> 

    </div>

);
export default Hero;