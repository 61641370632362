import React from 'react';
import style from '../styles/TextTestimonial.module.css';
import quote from '../icons/quote.svg'

export default class TextTestimonial extends React.Component {
	render() {
		const { ProfilePic, Position, Name, Quote } = this.props;
		return (
			<div className={style.Container}>
				<img src={quote} alt="" className={style.QuotationMarks} />
				<div className={style.Quote}>{Quote}</div>
				<div className={style.info}>
					<img src={ProfilePic} alt="Unknown" className={style.img} />
					<p className={style.position}> {Position}</p>
					<p className={style.name}>{Name}</p>
				</div>
			</div>
		);
	}
}
