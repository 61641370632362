import React from "react";
import style from "../styles/VideoTestimonialCarousel.module.css";
import arrowButton from "../icons/VideoTestimonialArrowButton.svg";

export default class VideoTestimonialCarousel extends React.Component {
  constructor() {
    super();
    this.state = { onAnimation: false };
    this.index = 0;
  }

  componentDidMount() {
    this.numOfCards = this.cards.childElementCount;
    for (let i = 0; i < this.numOfCards; i++) {
      this.cards.children.item(i).classList.add(style.card);
    }
    window.addEventListener("resize", this.onWindowResize);
    setTimeout(() => {
      this.onWindowResize();
    }, 1);
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  componentWillUnmount() {
    window.removeEventListener('resize', this.onWindowResize)
  }

  onWindowResize = () => {
    if (window.innerWidth <= 896) {
      this.handleAnimation();
    } else {
      for (let i = 0; i < this.numOfCards; i++) {
        this.cards.children
          .item(i)
          .classList.remove(style.right, style.center, style.left);
      }
    }
  };
  componentWillUnmount() {
    window.removeEventListener("resize", this.onWindowResize);
  }

  handleAnimation = (n = 0) => {
    this.setState({ onAnimation: true });
    let left, right;

    this.index = this.index + n;
    if (this.index > this.numOfCards - 1) this.index = 0;
    if (this.index < 0) this.index = this.numOfCards - 1;

    left = this.index - 1;
    if (left < 0) left = this.numOfCards - 1;

    right = this.index + 1;
    if (right > this.numOfCards - 1) right = 0;

    for (let i = 0; i < this.numOfCards; i++) {
      this.cards.children
        .item(i)
        .classList.remove(style.right, style.center, style.left);
    }
    this.cards.children.item(left).classList.add(style.left);
    this.cards.children.item(this.index).classList.add(style.center);
    this.cards.children.item(right).classList.add(style.right);
    return;
  };

  render() {
    return (
      <div className={style.videoCarousel}>
        <button
          className={style.button}
          onClick={() => this.handleAnimation(+1)}
          disabled={this.state.onAnimation}
        >
          <img src={arrowButton} alt="" />
        </button>
        <div
          className={style.container}
          ref={el => (this.cards = el)}
          onTransitionEnd={() => this.setState({ onAnimation: false })}
        >
          {this.props.children}
        </div>
        <button
          className={style.button}
          onClick={() => this.handleAnimation(-1)}
          disabled={this.state.onAnimation}
        >
          <img src={arrowButton} alt="" />
        </button>
      </div>
    );
  }
}
