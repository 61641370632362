import React from 'react';
import VideoTestimonial from './VideoTestimonial';
import TextTestimonial from './TextTestimonial';
import VideoTestimonialCarousel from './VideoTestimonialCarousel'
import { Carousel, SlideWrapper } from './Carousel';
import OkrahMichael from '../images/OkrahMichael.jpg';
import GabrieleJulia from '../images/GabrieleJulia.jpg';
import HorenKenneth from '../images/HorenKenneth.jpg';
import MillerStephenL from '../images/MillerStephenL.jpg';
import CepedaDavid from '../images/DavidCepda.jpg';
import StoneRoger from '../images/StoneRoger.jpg';
import OkrahMichaelVideo from '../Video/OkrahMichael.mp4';
import GabrieleJuliaVideo from '../Video/GabrieleJulia.mp4';
import CepedaDavidVideo from '../Video/CepedaDavid.mp4';
import MillerStephenLVideo from '../Video/MillerStephenL.mp4';
import style from '../styles/TestimonialContainter.module.css';

const textTestimonials = [
	{
		Name: 'Kenneth Horen',
		ProfilePic: HorenKenneth,
		Position: 'Global Program Manager',
		Quote:
			"I recently enrolled in Susan's PMP Boot Camp \
            training program. Susan presented the material \
            ulizing her unique training method to convert concepts \
            into long retenon learnings. Susan encouraged total \
            parcipant involvement and to take me to make certain \
            all of the students grasped the material prior to moving \
            on to the next concept. The course Susan developed has a \
            nice mix of video, book work, slide presentaon, and student \
            problem solving. Susan also supported her students \
            post course with study hall sessions, a very nice bonus \
            to help preparing for the exam. Thank you, Susan, I look \
            forward to taking future courses!"
	},
	{
		Name: 'Roger Stone',
		ProfilePic: StoneRoger,
		Position: 'Data Quality/Governance',
		Quote:
			'Susan provided me with private tutoring on project \
	        management in March of 2010, in preparaon for the pmp \
	        exam. Susan made the sessions fun and easy to learn. \
	        Susan understands project management and quality management \
	        extremely well, and presented me with new techniques to study \
	        for the exam. Susan your guidance on the use of mind mapping \
	        and the formula memorizaon strategy was invaluable. Susancompletely \
	        devoted herself to the task, working around my schedule both \
	        nights and weekends which I did not expect. I passed my exam \
	        just 2 weeks later. Thank you, Susan!'
	}
];
const videoTestimonials = [
	{
		Name: 'Michael Okrah',
		ProfilePic: OkrahMichael,
		Position: 'Senior Markeng Strategist ',
		Video: OkrahMichaelVideo
	},
	{
		Name: 'Julia Gabriele',
		ProfilePic: GabrieleJulia,
		Position: 'Senior Project Manager',
		Video: GabrieleJuliaVideo
	},
	{
		Name: 'Stephen L. Miller',
		ProfilePic: MillerStephenL,
		Position: 'Sales Support Manager',
		Video: MillerStephenLVideo
	},
	{
		Name: 'David Cepeda',
		ProfilePic: CepedaDavid,
		Position: 'Sales Support Manager',
		Video: CepedaDavidVideo
	}
];
export default class TestimonialsContainer extends React.Component {
	render() {
		return (
			<div className={style.pageTesContainer}>
				<div className={style.title}>
					<span>TESTIMONIALS</span>
				</div>
				<VideoTestimonialCarousel >
					{Object.keys(videoTestimonials).map((key) => (
						<VideoTestimonial
							key={key}
							ProfilePic={videoTestimonials[key].ProfilePic}
							Position={videoTestimonials[key].Position}
							Name={videoTestimonials[key].Name}
							src={videoTestimonials[key].Video}
							handelPlayVideo={this.handelPlayVideo}
							playPause={this.playPause}
						/>
					))}
				</VideoTestimonialCarousel>
				<Carousel
					className={style.TextCarousel}
					DotButton
					TimeOut="15000"
					render={(stopSlide) => (
						<React.Fragment>
							{Object.keys(textTestimonials).map((key) => (
								<SlideWrapper key={key}>
									<TextTestimonial
										ProfilePic={textTestimonials[key].ProfilePic}
										Position={textTestimonials[key].Position}
										Name={textTestimonials[key].Name}
										Quote={textTestimonials[key].Quote}
										handelPlayVideo={this.handelPlayVideo}
										key={key}
									/>
								</SlideWrapper>
							))}
						</React.Fragment>
					)}
				/>
			</div>
		);
	}
}
