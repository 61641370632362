import React from "react";
import { Link, withRouter } from "react-router-dom";
import Logo from "../icons/Whitecharacter-stacked-white.svg";
import Modal from "./Modal";
import ContactForm from "./ContactForm";
import SimpleBar from "simplebar";
import "../styles/Header.css";

class Header extends React.Component {
  //initiating state
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      scrollDown: true,
      oldScroll: 0,
      OffSet: 0,
      displayMobileNav: false
    };
  }
  componentDidMount() {
    const simpleBar = new SimpleBar(document.getElementById("root"));
    this.unlisten = this.props.history.listen(() => {
      this.setState({ displayMobileNav: !this.state.displayMobileNav });
    });
    simpleBar.getScrollElement().addEventListener("scroll", this.onScroll);
  }

  componentWillUnmount() {
    document.body.removeEventListener("scroll", this.onScroll);
    this.unlisten();
  }

  onScroll = e => {
    if (e.target.scrollTop > 0) {
      this._header.classList.add("HeaderPositionFixed");
      e.target.classList.add("BodyMargin");
    } else {
      this._header.classList.remove("HeaderPositionFixed", "HeaderTopOffSet");
      e.target.classList.remove("BodyMargin");
    }

    // Scroll Down
    if (this.state.oldScroll - e.target.scrollTop < 0) {
      if (this.state.scrollDown) {
        this.setState({ scrollDown: false, OffSet: e.target.scrollTop });
      }
      if (this.state.OffSet + 400 < e.target.scrollTop) {
        this._header.classList.add("HeaderTopOffSet");
      }
    }

    // Scroll up
    if (this.state.oldScroll - e.target.scrollTop > 0) {
      if (!this.state.scrollDown) {
        this.setState({ scrollDown: true, OffSet: e.target.scrollTop });
      }
      if (
        this.state.OffSet - 600 > e.target.scrollTop ||
        e.target.scrollTop < 300
      ) {
        this._header.classList.remove("HeaderTopOffSet");
      }
    }

    this.setState({ oldScroll: e.target.scrollTop });
  };

  //Contact alert
  handleOpenModal = () => {
    this.setState({ isOpen: true, displayMobileNav: false });
  };
  handleCloseModal = () => {
    this.setState({ isOpen: false });
  };

  handleHeader = event => {
    event.preventDefault();
    this.setState({ displayMobileNav: !this.state.displayMobileNav });
  };

  render() {
    const { About, Courses, OnLocation } = this.props;
    return (
      <div className="Header" ref={el => (this._header = el)}>
        <a href="/">
          <img className="Header-Logo" src={Logo} alt="" />
        </a>
        <div className="hamburger ">
          <div
            className={`mobile-bars ${
              this.state.displayMobileNav ? "change" : ""
            } `}
            onClick={this.handleHeader}
            onTouchEnd={this.handleHeader}
          >
            <div className="bar1"></div>
            <div className="bar2"></div>
            <div className="bar3"></div>
          </div>
        </div>

        <div
          className={`Header-navBar ${
            this.state.displayMobileNav ? "open" : ""
          } `}
        >
          <Link to="/">Home</Link>
          <Link to={About}>About</Link>
          <Link to={Courses}>Courses</Link>
          <Link to={OnLocation}>On Location</Link>
          <p onClick={this.handleOpenModal}>Contact</p>
        </div>

        {this.state.isOpen ? (
          <Modal
            isOpen={this.state.isOpen}
            handleCloseModal={this.handleCloseModal}
          >
            <ContactForm handleCloseModal={this.handleCloseModal} />
          </Modal>
        ) : null}
      </div>
    );
  }
}

export default withRouter(Header);
