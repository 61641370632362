import React from 'react';
import Event from './Event';
import '../styles/UpcomingEvent.css';

export default class UpcomingEvents extends React.Component {
	constructor() {
		super();
		this.state = {
			events: [],
			row1: [],
			row2: [],
			row3: [],
			position: 0,
			toggle: false,
			LongButtons: [],
			isOpen: false,
			CourseInfo: {},
			width: ''
		};
	}

	componentDidMount() {
		window.addEventListener('resize', this.update);
		fetch('https://api.smarthenterprises.com/events?active=true')
			.then((response) => response.json())
			.then((data) => this.setState({ events: Object.values(data) }, this.generateLongButtons))
		this.update()
	}

	componentWillMount() {
		window.removeEventListener('resize', this.update);
	}

	update = () => {
		this.setState({ width: window.innerWidth });
	};

	//reander events on scroll button click
	pagingEvents = () => {
		let column = this.state.events.slice(this.state.position, this.state.position + 6);
		setTimeout(() => {
			this.setState({
				row1: column.slice(0, 2),
				row2: column.slice(2, 4),
				row3: column.slice(4, 6),
				toggle: true
			});
		}, 300);
	};

	//Creating number of buttons for scrolling
	generateLongButtons = () => {
		const numberOfPages = Math.ceil(this.state.events.length / 6);
		for (let i = 0; i < numberOfPages; i++) {
			this.setState((prevState) => ({
				LongButtons: [
					...prevState.LongButtons,
					<button
						key={i}
						className={i === 0 ? 'LongButton LongButtonActive' : 'LongButton'}
						onClick={(e) => this.handleLongButtonClick(e, i)}
					/>
				]
			}));
		}
		this.pagingEvents();
	};

	//On scroll button click
	handleLongButtonClick = (e, id) => {
		if (this.state.position !== 6 * id) {
			document.querySelector('.LongButtonActive').classList.remove('LongButtonActive');
			this.setState({ toggle: false, position: 6 * id }, this.pagingEvents);
			e.target.classList.add('LongButtonActive');
		}
	};

	render() {
		let { row1, row2, row3, toggle } = this.state;
		return (
			<div className="upcomingEvent">
				<div className="title">UPCOMING EVENTS</div>
				{this.state.width > 764 ?

					<div className="container">
						<div className="rows">
							<div
								className={`row animated  ${toggle ? 'fadeInLeft' : 'fadeOutLeft'}
							${row3.length === 2 && row2.length === 2 ? 'row3' : ''}`}
							>
								{row1.map((event, i) => <Event key={event.Id} eventInfo={event} />)}
							</div>
							<div
								className={`row animated ${toggle ? 'fadeInRight' : 'fadeOutRight'} 
							${row3.length > 1 && row2.length > 1 ? 'row2' : ''}`}
							>
								{row2.map((event, i) => <Event key={event.Id} eventInfo={event} />)}
							</div>
							<div
								className={`row animated  ${toggle ? 'fadeInLeft' : 'fadeOutLeft'}
							${row3.length === 2 && row2.length === 2 ? 'row3' : ''}`}
							>
								{row3.map((event, i) => <Event key={event.Id} eventInfo={event} />)}
							</div>
						</div>

						<div ref={(e) => (this._ButtonContainer = e)} className="ButtonContainer">
							{this.state.LongButtons}
						</div>
					</div>
					:
					<div className="container">
						{this.state.events.map((event, i) => <Event key={event.Id} eventInfo={event} />)}
					</div>
				}
			</div>
		);
	}
}
