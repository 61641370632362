import React from 'react';

export default class BodyWrapper extends React.Component {
	componentDidUpdate() {
		// if the element is in viewport
		if (this.props.inViewPort) {
			// than add the animations classes to the patent
			this._parent.classList.add('animated', this.props.animated);
			this._parent.style.opacity = 1;
			let dur = 0.6;
			for (var i = 0; i < this._parent.childElementCount; i++) {
				// add the animations classes to each child
				this._parent.children[i].classList.add('animated', this.props.animated);
				// and increase the animation duration for each child
				this._parent.children[i].style.animationDuration = dur + 's';
				dur += 0.5;
			}
		}
	}
	render() {
		return (
			<div className={this.props.className} ref={(e) => (this._parent = e)}>
				{this.props.children}
			</div>
		);
	}
}
