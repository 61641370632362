import React from 'react';
import '../styles/Textarea.css';

const Textarea = ({ PlaceHolder, required, name, value, onChange }) => {
	return (
		<div className="Textarea">
			<textarea
				className="Textarea-Input"
				name={name}
				value={value}
				onChange={onChange}
				type="text"
				placeholder=" "
				required={required}
			/>
			<label className="Textarea-placeholder">
				<span className="Textarea-placeholder-text">{PlaceHolder}</span>
			</label>
		</div>
	);
};

export default Textarea;
