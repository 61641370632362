import React from "react";
import { Helmet } from "react-helmet";
import classrom from "../icons/classroom.svg";
import topicTriangle from "../icons/Polygon.svg";
import teachingPic from "../images/TeachingImg.png";
import Modal from "./Modal";
import ContactForm from "./ContactForm";
import Hero from "./Hero";
import heroStyle from "../styles/Hero.module.css";
import style from "../styles/onLocation.module.css";
import circularImg from "../icons/PaperTopic.svg";

export default class onLocationPage extends React.Component {
  //initiating state
  constructor() {
    super();
    this.state = {
      isOpen: false,
      hero: {
        hero: {
          Title: "At Your Location",
          Content: "Onsite Training and Professional Development for your TEAM!"
        }
      },

      training: {
        trainingInfo: {
          Title: "Does your organization have employees in need of training?",
          Content: [
            "Smarth Enterprises has a variety of training options to help accomplish your corporate goals. We can hold a course at your location or your employees can come to our classroom, or attend virtually live over time. Anything to keep your projects moving as the team gain valuable skill sets.",
            "We can work with you to identify custom requirements for specialized delivery. Scheduling and flexibility are major keys to working with Smarth Enterprises at your location."
          ],
          Image: classrom
        }
      },

      topics: {
        topicInfo: {
          Title: "Topics include, but not limited to:",
          Content: [
            "Project Management Professional (PMP)®",
            "Certified Associate in Project Management (CAPM)®",
            "Project management overview/fundamentals (1 to 4 days)",
            "Program management overview/fundamentals (1 to 3 days)",
            "Portfolio management overview/fundamentals (1 to 2 days)",
            "MS Project 2016 (2-4 days)",
            "Jira Fundamentals (2 days)",
            "Agile Fundamentals (2 days)",
            "Scrum Fundamentals (2 days)"
          ],
          Image: circularImg,
          Polygon: topicTriangle
        }
      },

      contactUs: {
        contact: {
          Title1: `To discuss further details about `,
          Bold: "onsite training",
          Title2: " that works for you, click the button bellow!",
          Content: "Contact Us",
          Image: teachingPic
        }
      }
    };
  }

  //Contact alert
  handleOpenModal = () => {
    this.setState({ isOpen: true });
  };
  handleCloseModal = () => {
    this.setState({ isOpen: false });
  };

  render() {
    return (
      <div>
        <Helmet>
          <title>Convenient on-site training | Develop your team</title>
          <meta
            name="description"
            content="Let us help train your team with a wide variety of courses to help accomplish your corporate goals. Flexible scheduling to accommodate your needs."
          />
        </Helmet>
        {Object.keys(this.state.hero).map(key => (
          <Hero
            Title={this.state.hero[key].Title}
            Content={this.state.hero[key].Content}
            Image={this.state.hero[key].Image}
            key={key}
            style={heroStyle}
          />
        ))}

        <div className={style.GridView}>
          <div className={style.trainingContainer}>
            <div className={style.infoTitle}>
              {this.state.training.trainingInfo.Title}
            </div>

            <div className={style.textImgWrap}>
              <img
                src={this.state.training.trainingInfo.Image}
                alt="clssRoom"
                className={style.clsRoomImg}
              />
              <div className={style.content}>
                {this.state.training.trainingInfo.Content.map((content, i) => (
                  <p key={i}> {content}</p>
                ))}
              </div>
            </div>
          </div>

          <div className={style.mainContainer}>
            <div className={style.topicContent}>
              <div
                className={[style.topicTitle, style.botTopPadding].join(" ")}
              >
                {this.state.topics.topicInfo.Title}
              </div>
              <div className={style.topicOptions}>
                {this.state.topics.topicInfo.Content.map((content, i) => (
                  <div className={style.individualTopic} key={i}>
                    <img
                      className={style.topicTriangle}
                      src={this.state.topics.topicInfo.Polygon}
                      alt="topTriangle"
                    />
                    {content}
                  </div>
                ))}
              </div>
            </div>
            <div className={style.topicImgCont}>
              <img
                src={this.state.topics.topicInfo.Image}
                alt="group"
                className={style.circlePaperImg}
              />
            </div>
          </div>

          <div className={style.ContactUsContainer}>
            <div className={style.contContactUSText}>
              <div className={style.contactUsText}>
                {this.state.contactUs.contact.Title1}
                <b className={style.highlightText}>
                  {this.state.contactUs.contact.Bold}
                </b>
                {this.state.contactUs.contact.Title2}
              </div>
              <button
                className={style.contactBtn}
                onClick={this.handleOpenModal}
              >
                {this.state.contactUs.contact.Content}
              </button>
            </div>
            <div className={style.contContactUS}>
              <img
                src={this.state.contactUs.contact.Image}
                alt="teaching pic"
                className={style.teachingPic}
              />
            </div>
          </div>
        </div>
        {this.state.isOpen ? (
          <Modal
            isOpen={this.state.isOpen}
            handleCloseModal={this.handleCloseModal}
          >
            <ContactForm handleCloseModal={this.handleCloseModal} />
          </Modal>
        ) : null}
      </div>
    );
  }
}
