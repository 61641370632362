import React from 'react';
import styles from '../styles/Confirmation.module.css';

const Confirmation = ({ title, SubTitle }) => {
	return (
		<div className={`animated fadeIn ${styles.Confirmation}`}>
			<div className={styles.Title}>{title ? title : 'Thank You For Contacting Us!'}</div>
			<div className={styles.SubTitle}>{SubTitle ? SubTitle : 'Expect a response within 48-hours'}</div>
		</div>
	);
};
export default Confirmation;
